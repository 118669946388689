import styled from "styled-components";

export const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.background.navy};
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: #121640;
  padding-bottom: 60px;
`

export const TitleText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  color: #121640;
  //transition: all 0.5 ease-in-out;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
  }
`