import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Pricing from "../components/views/Pricing"
import Faq from "../components/views/Pricing/faq"
import FeatureMatrix from "../components/views/Pricing/FeatureMatrix";

const PricingPage = ({location}) => {
    return (
        <Layout location={location}>
            <SEO title="Pricing"/>
            <Pricing/>
            <FeatureMatrix/>
            <Faq/>
        </Layout>
    )
}

export default PricingPage
