import React, { useState } from "react"
import styled from "styled-components"
import { Container } from "../../../styles/global"
import DoownIcon from "./../../../images/pricing/downIcons.svg"
import {SectionTitle, TitleText} from "./common";
const Faq = () => {
  const [open, setOpen] = useState(1)
  const handleCollapse = (id) => {
    if (open === id) {
      setOpen(!id)
    } else {
      setOpen(id)
    }
  }
  return (
    <Wrapper>
      <SectionTitle>FAQs</SectionTitle>
      <Container>
        <Main>
          {questions.map((li) => {
            const { id, question, answer } = li

            return (
              <FaqWrapper key={id}>
                <QuestionTab onClick={() => handleCollapse(id)}>
                  <TitleText>{question}</TitleText>

                  <IconWrapper>
                    <img
                      src={DoownIcon}
                      alt=""
                      className={open === id ? "open-icon" : "close-icon"}
                    />
                  </IconWrapper>
                </QuestionTab>
                <div className={`${open === id ? "open" : "collapse"} `}>
                  <Answer>{answer}</Answer>
                </div>
              </FaqWrapper>
            )
          })}
        </Main>
        <FooterWrappper>
          <FooterPara>Our support is always available at</FooterPara>
          <FooterEmail>hello@jinolo.com</FooterEmail>
        </FooterWrappper>
      </Container>
    </Wrapper>
  )
}

export default Faq
const Wrapper = styled.div`
  padding-top: 59px;
  padding-bottom: 16px;
  background-color: #f5f5f5;
`

const Main = styled.div`
  margin: 0 auto 32px;
`
const FaqWrapper = styled.div`
  margin: 0 auto 20px;
  padding: 10px 25px;
  background-color: #ffffff;
  border-radius: 10px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding: 10px 10px;
  }
`
const QuestionTab = styled.div`
  width: 100%;
  padding: 16px 0px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`

const IconWrapper = styled.div`
  margin-right: 16px;
  display: flex;
  align-self: center;
`
const Answer = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #121640;
`
const FooterWrappper = styled.div`
  margin: 86px 0px;
`
const FooterPara = styled.p`
  font-style: normal;
  font-weight: 200;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #121640;
`
const FooterEmail = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #121640;
`

const questions = [
  {
    id: 1,
    question: "What is the difference between public link sharing and private sharing?",
    answer:
      "Public link sharing, available in our Starter tier, allows you to generate a unique URL for your project or file which can then be shared with anyone. They can access your shared item simply by clicking the link, even if they don't have an account with our service.\n" +
        "\n" +
        "On the other hand, private email sharing, available in our Professional and Enterprise tiers, enables you to share your project or file directly with specific email addresses. The recipients are required to log into their accounts to access the shared content, ensuring a higher level of security and access control.",
  },
  {
    id: 2,
    question: "Can anyone access my files if I use the public link sharing feature?",
    answer:
      "Yes, public link sharing means the file or project can be accessed by anyone who has the link. However, it's important to remember that unless you publicize the link broadly, only those with whom you share the link directly will know it exists." +
        "While public link sharing is convenient, it doesn't provide the same level of security and control as private email sharing. Anyone with the link can view the shared content. If you need to share sensitive information, we recommend upgrading to our Professional or Enterprise tier and using the private email sharing feature.",
  },
  {
    id: 3,
    question: "How secure is our data on your platform?",
    answer: "We take data security very seriously. All user data stored on our platform is backed by Google Cloud Storage, one of the most secure and reliable cloud storage solutions available today. Your data is encrypted both at rest and in transit, ensuring the utmost protection against any unauthorized access. It's important to note that your data is not shared with anyone unless you choose to do so.",
  },
  {
    id: 4,
    question: "Do you have discounts?",
    answer:
      "You get a discount if you pay annually. We also occasionally run promotions and give discount codes. Subscribe to our newsletter to stay up to date on any special deals. ",
  },
  {
    id: 5,
    question: "If I share a file, does the recipient need a paid subscription to access it?",
    answer:
      "No, the recipient does not need a paid subscription to access a file you've shared. They simply need to have a free Starter account with us. The Starter account allows users to receive and view an unlimited number of files, providing easy access to all the shared content. However, it's worth noting that the Starter account comes with a storage limit of 1GB for file uploads. This means that while they can view any number of files shared with them, they're limited in terms of the number of documents they can upload to their own account.",
  },
  {
    id: 6,
    question: "What happens if I need more storage?",
    answer:
      "If you need more storage, contact us on hello@jinolo.com and we will happily adjust it in your account. ",
  }
]
