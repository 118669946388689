import React from "react"
import styled from "styled-components"
import True from "./../../../images/pricing/tickIcon.svg"
import SignUpButton from "../../common/signUpButton";

const Pricing = () => {
    const handleContactUsClick = ({url}) => {
        console.log(url)
        if (url) {
            window.location.href = url;
            window.gtag("event", "contact_us_click", {event_category: "Button", event_label: "ContactUs"});
        }
    }
    return (
        <Wrapper>
            <div className="text-navy content-center lg:px-10 xl:px-32">
                <Title>Start sharing your designs today.</Title>
                <div className="mx-auto max-w-screen-2xl">
                    <div className="flex grid-cols-1 md:grid-cols-2 lg:grid-cols-4
                                xl:grid-cols-4 gap-2 lg:gap-3 xl:gap-3">
                        {PricingCards.map(({id, price, name, button, url, credits, plans, list}) => {
                            return (
                                <div key={id}
                                     className="flex flex-col justify-between p-5 border-2 border-gray-200 rounded-lg
             bg-white max-w-xs mx-auto hover:border-accent">
                                    <div>
                                        <Name>{name}</Name>
                                        <div
                                            className={`text-center font-bold ${id === 4 ? 'text-6xl' : 'text-9xl mb-4'} relative`}>
                                            <div className="flex justify-center">
                                                {id !== 4 && <DollarSign>$</DollarSign>}
                                                {price}
                                            </div>
                                        </div>

                                        {plans.map((item, index) => (
                                            <Plans key={index} className="text-center">{item}</Plans>
                                        ))}

                                        <div className="space-y-3 mt-4">
                                            {list.map((item, index) => (
                                                <List key={index}>
                                                    <ImgWraper src={True} alt=""/>
                                                    {item}
                                                </List>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="flex justify-center items-center py-2">
                                            {id === 4 ?
                                                <div className="border-2 w-11/12 border-primary font-grotesk-semi-bold py-2
                        text-lg bg-primary h-11
                        text-white uppercase rounded-full
                        hover:bg-white hover:text-primary cursor-pointer text-center"
                                                     onClick={() => handleContactUsClick({url})}
                                                >
                                                    {button}
                                                </div>
                                                :
                                                <SignUpButton className="w-11/12" buttonText={button}/>
                                            }
                                        </div>
                                        <Credits>{credits}</Credits>
                                    </div>
                                </div>
                            )
                        })}

                        {/*</Row>*/}
                    </div>
                </div>
                    <Credits>All prices are in USD.</Credits>
                </div>
        </Wrapper>
)
}

export default Pricing

const Wrapper = styled.div`
  padding-top: 159px;
  padding-bottom: 100px;
  background-color: #f5f5f5;
`

const Title = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: #121640;
  margin-bottom: 44px;
  text-align: center;
  margin-top: 0;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    font-size: 32px;
  }
`

const Plans = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  //margin-bottom: 10px;
`
const List = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #121640;
  margin-bottom: 15px;
  display: flex;
  gap: 9px;
`
const Credits = styled.div`
  color: #6a6a6a;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`

const Name = styled.div`
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 33px;
`
const Price = styled.div`
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
`
const Price2 = styled.div`
  font-size: 100px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  max-width: 170px;
  //margin: auto;
`
const DollarSign = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #121640;
  height: 100%;
  display: flex;
  //align-items: flex-start;
  //position: absolute;
  top: 16px;
  left: 8px;
`

const Button = styled.a`
  color: white;
  text-transform: uppercase;
  background-color: #00c4cc;
  border: 1px solid #00c4cc;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 62px auto 8px auto;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14px;
  max-width: 181px;
  width: 100%;
  text-decoration: none;

  &:hover {
    background-color: #ffffff;
    color: #00c4cc;
  }
`
const ImgWraper = styled.img`
  width: 15px;
  height: 11px;
`

const PricingCards = [
    {
        id: 0,
        price: "0",
        name: "Free",
        plans: ["per user/month", " "],
        list: [
            "Receive and review unlimited 3D and 2D designs on any device",
            "Add comments in real-time",
            "Upload up to 3 files",
            "Share files using a public link",
            "Files stored for 30 days"
        ],
        button: "Sign Up Free",
        url: "https://share.jinolo.com/",
        credits: "No credit card required",
    },
    {
        id: 1,
        price: "8",
        name: "Starter",
        plans: ["per user/month", "or $10 billed monthly"],

        list: [
            "All benefits of Free plan",
            "Upload up to 1GB (then $8 per 5GB/month)",
            "Share via public link or privately invite using email address",
            "Larger file size upload",
            "Files stored forever"

        ],
        button: "Get Starter",
        url: "https://share.jinolo.com/",
        credits: "No credit card required",
    },
    {
        id: 2,
        price: "29",
        name: "Professional",
        plans: ["per user/month", "or $32 billed monthly"],
        list: [
            "All benefits of Starter plan",
            "Upload up to 10GB (then $8 per 5GB/month)",
            "Larger file size limits for comprehensive uploads",
            "Better file organisation with folders and teams",
            "More permission control and security features"
        ],
        button: "Get Professional",
        url: "https://share.jinolo.com/checkout",
        credits: "Cancel anytime.",
    },
    {
        id: 4,
        price: "Custom Pricing",
        name: "Enterprise",
        plans: [
            "Specific requirements?",
            " "
            // "Contact us to find out more.",
        ],
        list: [
            "Includes all Professional plan features",
            "Customized storage solutions as per business needs",
            "Enterprise-grade security with audit logs",
            "Admin level team management for greater control",
            "Dedicated support and account insights for business optimization"
        ],
        button: "Contact Us",
        url: "https://calendly.com/jinolo/jinolo-onboarding",
        credits: (<span>&nbsp;</span>),
    },
]
