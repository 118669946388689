import React from 'react';
import styled from "styled-components";
import {Container} from "../../../styles/global";
import {SectionTitle} from "./common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';

// FeatureSectionHeading Component
function FeatureSectionHeading({ title }) {
    return (
        <div className="grid grid-cols-4 py-6 lg:text-xl text-md font-grotesk-semi-bold">
            <div className="text-navy ">{title}</div>
            <div  />
            <div  />
            <div />
        </div>
    );
}
// FeatureRow Component
function FeatureRow({feature, free, starter, professional, enterprise}) {

    // const renderValue = (value) => {
    //     if (typeof value === 'boolean') {
    //         return value ? '✓' : '—';
    //     }
    //     return value || '—';
    // };

    const renderValue = (value) => {
        if (typeof value === 'boolean') {
            return value ? <FontAwesomeIcon className="text-primary" icon={faCheck} /> : <FontAwesomeIcon className="text-gray-500" icon={faMinus} />;
        }
        return value || '—';
    };

    return (
        <div className="grid grid-cols-5  font-grotesk-normal text-sm text-navy">
            <span className="pl-2 py-2">{feature}</span>
            <span className="text-center py-2 border-r">{renderValue(free)}</span>
            <span className="text-center py-2 border-r">{renderValue(starter)}</span>
            <span className="text-center py-2 border-r">{renderValue(professional)}</span>
            <span className="text-center py-2 ">{renderValue(enterprise)}</span>
        </div>
    );
}

// FeatureMatrix Component
// function FeatureMatrix({ features }) {
function FeatureMatrix() {
    return (
        <Wrapper>
            <Container>
                <SectionTitle>Compare plans</SectionTitle>
                <div className="bg-white px-4 py-10 rounded-lg">
                    {/*<div className="w-full">*/}
                    <div className="grid grid-cols-5  font-grotesk-semi-bold lg:text-3xl text-lg">
                        <div/>
                        <div className="text-navy py-2 text-center">
                            Free
                        </div>
                        <div className="text-navy py-2 text-center">
                            Starter
                        </div>
                        <div className="text-navy py-2 text-center">
                            Professional
                        </div>
                        <div className=" text-navy py-2 text-center">
                            Enterprise
                        </div>
                    </div>
                    {/*{FEATURES.map((feat, index) => (*/}
                    {/*    <FeatureRow key={index} {...feat} />*/}
                    {/*))}*/}

                    {FEATURES.map((feat, index) => (
                        <div key={index}>
                            {feat.section ? <FeatureSectionHeading title={feat.section} /> : <FeatureRow {...feat} />}
                        </div>
                    ))}

                </div>
            </Container>
        </Wrapper>
    );
}

// Define the constant JavaScript object below to manage your features:
const FEATURES = [
    {section: "File viewing and storage" },
    {feature: "View 3D design in the browser", free: true, starter: true, professional: true, enterprise: true},
    {feature: "View 2D file in the browser", free: true, starter: true, professional: true, enterprise: true},
    {feature: "Number of files can receive", free: "Unlimited", starter: "Unlimited", professional: "Unlimited", enterprise: "Unlimited"},
    {feature: "File size limit (except STEP file)", free: "50mb", starter: "150mb", professional: "Unlimited", enterprise: "Unlimited"},
    {feature: "STEP file size limit", free: "50mb", starter: "150mb", professional: "300mb", enterprise: "As required"},
    {feature: "Account storage limit", free: "-", starter: "1GB", professional: "10GB", enterprise: "As required"},
    {feature: "Number of uploaded files", free: "3", starter: "Unlimited (up to 1GB total)", professional: "Unlimited (up to 10GB total)", enterprise: "As required"},
    {feature: "File storage duration", free: "30 days", starter: "Forever", professional: "Forever", enterprise: "Forever"},
    {feature: "Folders", free: false, starter: true, professional: true, enterprise: true},
    {section: "Collaboration and sharing" },
    {feature: "Commenting", free: true, starter: true, professional: true, enterprise: true},
    {feature: "Public link sharing", free: true, starter: true, professional: true, enterprise: true},
    {feature: "Private email sharing", free: false, starter: true, professional: true, enterprise: true},
    {section: "Account Management" },
    {feature: "Account Management", free: true, starter: true, professional: true, enterprise: true},
    {feature: "Subscription management", free: true, starter: true, professional: true, enterprise: true},
    {feature: "Centralized Account with unlimited teams", free: false, starter: false, professional: false, enterprise: true},
    {feature: "Audit Logs", free: false, starter: false, professional: false, enterprise: true},
    {feature: "Admin Insights", free: false, starter: false, professional: false, enterprise: true},
    // ... Add more features as necessary
];




// Usage:
// <FeatureMatrix features={FEATURES} />

export default FeatureMatrix;

const Wrapper = styled.div`
  padding-top: 59px;
  padding-bottom: 16px;
  background-color: #f5f5f5;
`
